import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/instagram-follow-like.png';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';

// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="Instagramフォロー&いいねキャンペーン"
		  desc="このサンプルはInstagramでフォロー&いいねキャンペーンを行う方法です。いいねとフォローをするだけの参加ハードルの低く応募が集まりやすい方法です。ユーザーにいいねしてもらうことで、御社のInstagramアカウントとのエンゲージメントが深くなり、Instagramでのコンテンツが表示されやすくなります。"
		  banner={exampleMetaImage}
		  pathname={'/examples/twitter/follow-like'}
		  type={'Article'}
		  shortName="Instagramのフォロー&いいねキャンペーン"
		  createdAt="2021-11-27"
		/>
	  <Navbar />
	  <ContentBanner
		pageTitle="Instagramフォロー&いいねキャンペーン"
		homePageText="Home"
		homePageUrl="/"
		activePageText="Instagramフォロー&いいねキャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/instagram',
		  name: 'Instagramキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"Instagramフォロー&いいねキャンペーン"}
		description={<>このサンプルはInstagramでフォロー&いいねキャンペーンを行う方法です。いいねとフォローをするだけの参加ハードルの低く応募が集まりやすい方法です。<br/>ユーザーにいいねしてもらうことで、御社のInstagramアカウントとのエンゲージメントが深くなり、Instagramでのコンテンツが表示されやすくなります。</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS,Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS,Constants.CAMPAIGN_EFFECTS.GET_FOLLOWERS
		  , Constants.CAMPAIGN_EFFECTS.INCREASED_ENGAGEMENT, Constants.CAMPAIGN_EFFECTS.RETENTION]}
		plan={'ライトプラン以上'}
		promotionId="004fa75a300c3da259"
		platformExample={
		  <PlatformExample
			title="その他のInstagramキャンペーン"
			types={['instagramFollowRepost', 'instagramFollowHashtag', 'password', 'instagramShare', 'instagramPhotoContest']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples